import axios from 'axios';

import {API_URL, API_REQUEST_TIMEOUT} from "../utils/Constants";
import { IAuthentication, UserRole } from '../interfaces/authentication';
import { StateLoader } from '../redux/StateLoader';
import { userInfo } from 'os';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import JSZip from 'jszip';

export interface IResponse {
    data: string,
    status: number,
    statusText: string,
    dateTime: string
}

//TODO: AdminID: id redux

axios.defaults.baseURL = API_URL;
axios.defaults.timeout = API_REQUEST_TIMEOUT;
//axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
//axios.defaults.headers = { 'Content-Type': 'application/json; charset=utf-8' };
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept";

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let res: IResponse = {} as IResponse;
        res.dateTime = error.response.headers['server-date'];
        res.data = error.response.data;
        res.status = error.response.status;
        res.statusText = error.response.statusText;

        if(res.status === 401)
        {
            loader.removeAuthLoader();
        }
        else if(res.status === 450){
            toast.error(t('error450'))
            throw res;
        }


        return Promise.reject(res);
    }
);

const loader = new StateLoader();
const authInfo : IAuthentication = loader.loadAuthState()

class HTTPService {
    static API_URL: any = API_URL;

    static LOGIN = (URL: string, query: object = {}) => {
        query = query || {};

        let response: IResponse = {} as IResponse;
        return axios
            .get(URL,{
                params: query
            })
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return error;
            });
    }

    static GET = (URL: string, query: object = {}) => {
        query = query || {};
        let response: IResponse = {} as IResponse;
        return axios
            .get(URL,{
                params: query,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return error;
            });
    }

    static GETBlob = async (URL: string,  query?: Object) => {
       
       
        return axios
            .get(URL, {
                params: query,
                responseType: 'blob',
                headers: {
                    //"responseType":"blob",
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((response) => {
                console.log(response);
                return response.data.arrayBuffer();
            })
            .then((buffer) => {
                // ArrayBuffer'dan yeni Blob oluştur
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'template.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch((response) => {
                return response;
            });
    }
    static GETUserNames = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/User/allUserName?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }
    static GETCompanyNames = async () => {
        if(authInfo.role != UserRole.AltisAdmin){
            return [];
        }
        let tmp = [];
     await axios
            .get(`/Company/ALLCompanyName`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETDebitNames = async () => {
        if(authInfo.role != UserRole.AltisAdmin){
            return [];
        }
        let tmp = [];
     await axios
            .get(`/Company/AllCompanyNames`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETCategoryNames = async () => {
        if(authInfo.role != UserRole.AltisAdmin){
            return [];
        }
        let tmp = [];
     await axios
            .get(`/Category/allCategoryName`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETCategoryByCompany = async (companyId:number) => {
        let tmp = [];
        console.log(companyId)
     await axios
            .get(`/Category/allCategoryNameByCompany?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }


    static GETDepartmentNames = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/Department/allDepartmentName?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETBrandNames = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/Brand/allBrandName?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETTypeNames = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/Type/allTypeName?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    
    static GETTypeNamesByCategory = async (categoryId:string) => {
        let tmp = [];
     await axios
            .get(`/Type/allTypeNameByCategory?categoryId=${categoryId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }



    static GETSupplierNames = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/Supplier/allSupplierName?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }
    static GETSupplierNamesByCompany = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/Supplier/allSupplierNameByCompany?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }


    static GETallLocationNameByCampus = async () => {
        let tmp = [];
     await axios
            .get(`/Location/allLocationNameByCampus`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETCampusNames = async () => {
        let tmp = [];
     await axios
            .get(`/Campus/allCampusName`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETAllLocationNameByCampus = async (campusId:string) => {
        let tmp = [];
     await axios
            .get(`/Location/allLocationNameByCampus?campusId=${campusId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETAllLocationNames = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/Location/allLocationName?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }



    static GETAllInventorStatusName = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/InventoryStatus/allInventoryStatusName?campusId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    
    static GETUsersByCompany = async (companyId: number) => {
        let tmp = [];
     await axios
            .get(`/User/allUserName`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static GETCampusByCompany = async (companyId: number) => {
        let tmp = [];
     await axios
            .get(`/Campus/allCampusNameByCompany?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }


    static GETInventoryStatusNames = async (companyId:number) => {
        let tmp = [];
     await axios
            .get(`/InventoryStatus/allInventoryStatusName?companyId=${companyId}`,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                if(result.status === 200){
                    tmp =  result.data;
                }
                else{
                    console.error(result)
                }
            })
            .catch((response) => {
                console.error(response)
            });
            return tmp;
    }

    static POST = (URL: string, data: Object, query? :Object) => {
        return axios
            .post(URL, data,{
                params: query,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },
            })
            .then((result) => {
                console.log(result)
                return result;
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    static async POSTBlob(URL: string, data: Object, query?: Object) {
        try {
         //await new Promise(resolve => setTimeout(resolve, 30000));

          const response = await axios.post(URL, data, {
            params: query,
            responseType: 'blob',
            headers: {
              'Access-Control-Allow-Origin': '*', // Adjust based on your CORS setup
              "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
              "Access-Control-Allow-Credentials": "true",
              "Authorization": authInfo?.token
            }
          });
      
          console.log('API response:', response);

          if (response.status === 400) {
            console.warn('Received empty or invalid response data');
            return []; // Return an empty array if no data
        }
      
          const blob = await response.data;
          const arrayBuffer = await blob.arrayBuffer();
      
          // Process the ZIP file using JSZip
          const zip = await JSZip.loadAsync(arrayBuffer);
          const imageFiles = Object.keys(zip.files).filter(filename => !zip.files[filename].dir);
      
          const imagePromises = imageFiles.map(async (filename) => {
            try {
              const file = zip.files[filename];
              const imageData = await file.async("base64");
              return {
                name: filename,
                src: `data:image/png;base64,${imageData}` // Adjust based on image type
              };
            } catch (error) {
              console.error(`Error processing file ${filename}:`, error);
              return null;
            }
          });
      
          const images = (await Promise.all(imagePromises)).filter(image => image !== null);
          return images;
        } catch (error) {
          console.error('Error fetching or processing ZIP:', error);
          return []; // Return an empty array on error
        }
    }

    static async POSTblob(URL: string, data: Object, query?: Object) {
        try {
            const response = await axios.post(URL, data, {
                params: query,
                responseType: 'blob', // Blob türünde veri beklediğimizi belirtiyoruz
                headers: {
                    'Access-Control-Allow-Origin': '*', // CORS başlıkları (gerekiyorsa ayarlayın)
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token // Yetkilendirme başlığı
                }
            });
    
            // Yanıtın status kodunu kontrol edin
            if (response.status != 200) {
                console.warn('Request failed with status:', response.status);
                return; // Hatalı durumlarda hiçbir şey yapma
            }
    
            // Blob nesnesini al
            const blob = response.data;
    
            // Blob'u bir URL'ye dönüştür
            const url = window.URL.createObjectURL(blob);
    
            // Kullanıcıya indirme bağlantısını sun
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'barcode.pdf'); // İndirilecek dosyanın adı
            document.body.appendChild(link);
            link.click();
    
            // Bağlantıyı temizle
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error fetching or processing PDF:', error);
        }
    }
    

    static PUT = (URL: string, data: Object) => {
        let response: IResponse = {} as IResponse;
        return axios
            .put(URL, data,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },}
                )
                .then((result) => {
                    return result;
                })
                .catch((error) => {
                    return error;
                });
    }

    static DELETE = (URL: string) => {
        return axios
            .delete(URL,{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Access-Control-Allow-Credentials": "true",
                    "Authorization": authInfo?.token
                },}
            )
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return error;
            });
         
    }
    
}

export default HTTPService;