import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Row} from 'antd';
import HTTPService from '../../utils/makerequest';
import { Column, Pie } from '@ant-design/plots';
import './../../assets/sass/components/Dashboard.scss';
import { IPageData } from '../../interfaces/page-data';
import { t } from 'i18next';
import { IAuthentication } from '../../interfaces/authentication';
import { StateLoader } from '../../redux/StateLoader';
import AltisDataGrid, { IAltisDataGridColumns } from '../../AltisComponent/AltisDataGrid';
import { ITrackings } from '../../interfaces/ITracking';
import toast from 'react-hot-toast';
import { ICountings } from '../../interfaces/ICountings';

const Dashboard: React.FunctionComponent<any> = props => {
  const { onSetPage } = props;   
  const [chartData, setChartData] = useState<any[]>([]);
  const [pieData, setPieData] = useState<any[]>([]);
  const [shapeData, setShapeData] = useState<any[]>([]);
  const [countingJobData, setCountingJobData] = useState<ICountings>({});
  const [historyData, setHistoryData] = useState<ITrackings>({});
  const [barData, setBarData] = useState<any[]>([]);
  const loader = new StateLoader();

  const pageData: IPageData = {
    title: t('dashboard'),
    loaded: true
  };


  useEffect(() => {
    onSetPage(pageData);
    getWidgets();
    TrackingFilter();
    CountingFilter();
  }, []);

  

  const getWidgets = () => {
    HTTPService.GET(`/Dashboard/allInventoryStatusCount`)
      .then(response => {
        if (!response.data) {
          return;
        }
        const pieChartData = response.data.map(item => ({
          type: item.inventoryStatusName, 
          Envanter_Sayısı: item.count,            
        })) || [];
        setPieData(pieChartData);
      })
      .catch(error => {
        console.error('Error fetching pie data:', error);
      });

    HTTPService.GET(`/Dashboard/allInventoryCategoryCount`)
    .then(response => {
      if (!response.data) {
        return;
      }
      console.log(response.data)
      const categoryChartData = response.data.map(item => ({
        type: item.categoryName, // API'den gelen kategori adı
        Kategori: item.count, // API'den gelen kategori sayısı
      }));
      console.log(categoryChartData)
      setShapeData(categoryChartData); 
    })
    .catch(error => {
      console.error('Error fetching category data:', error);
    });

    //  HTTPService.GET(`/Dashboard/inventoryByCounting`)
    //  .then(response => {
    //    if (!response.data) {
    //      return;
    //    }
    //   console.log(response.data);

    //    const countingJobData = response.data.map(item => ({
    //      key: item.countingJobId,
    //      countingJobName: item.countingJobName,
    //      completeCount: item.completeCount,
    //      totalCount: item.totalCount,
    //      countingStatus: item.countingStatus,
    //      responsibleName: item.responsibleName,
    //      startDate: new Date(item.startDate).toLocaleDateString(),
    //      finishDate: new Date(item.finishDate).toLocaleDateString(),
    //    }));
      
    //    console.log(countingJobData)
    //    setCountingJobData(countingJobData);
    //    console.log(response.data)

    //  })
    //  .catch(error => {
    //    console.error('Error fetching counting job data:', error);
    //  });

    HTTPService.GET(`/Dashboard/inventoryByCountingAsc`)
  .then(response => {
    if (!response.data) {
      return;
    }
    console.log(response.data);

    const monthlyTotals = {};

    response.data.forEach(item => {
      const startDate = new Date(item.startDate);
      const month = startDate.toLocaleString('tr-TR', { month: 'long', year: 'numeric' });

      if (!monthlyTotals[month]) {
        monthlyTotals[month] = {
          month,
          completeCount: 0,
          totalCount: 0,
        };
      }

      monthlyTotals[month].completeCount += item.completeCount;
      monthlyTotals[month].totalCount += item.totalCount;
    });

    const chartDataFromApi = Object.values(monthlyTotals);

    setChartData(chartDataFromApi);
  });

    // HTTPService.GET(`/Dashboard/inventoryByCounting`)
    // .then(response => {
    //   if (!response.data) {
    //     return;
    //   }
  
    //   // Bar verisini uygun formata dönüştürün
    //   const countingJobData = response.data.map(item => ({
    //     pageNumber:1,
    //     pageSize:10,
    //     key: item.countingJobId,
    //     countingJobName: item.countingJobName,
    //     completeCount: item.completeCount,
    //     totalCount: item.totalCount,
    //     countingStatus: item.countingStatus,
    //     responsibleName: item.responsibleName,
    //     remainingTime:item.remainingTime,
    //     startDate: new Date(item.startDate).toLocaleDateString(),
    //     finishDate: new Date(item.finishDate).toLocaleDateString(),
    //   }));

    //   console.log(countingJobData)
  
    //   setCountingJobData(countingJobData) 
    // })
    // .catch(error => {
    //   console.error('Error fetching bar data:', error);
    // });

  };

  const TrackingFilter = (e?) => {
   
    const body = {
      pageSize: e?.pageSize ?? 10,
      page: e?.page ?? 1,
      inventoryStatusName:e?.inventoryStatusName,
      updatedDate:e?.updatedDate,
      inventoryName:e?.inventoryName,
      inventoryTracking:e?.inventoryTracking,
      responsibleName:e?.responsibleName
    }

    HTTPService.POST(`/InventoryTracking/inventoryAllTracking`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        console.log(response.data)
        setHistoryData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };


  const CountingFilter = (e?) => {
   
    const body = {
      pageSize: e?.pageSize ?? 10,
      page: e?.page ?? 1,
      countingJobName:e?.countingJobName,
      remainingTime:e?.remainingTime,
      responsibleName:e?.responsibleName,
      startDate:e?.startDate,
      finishDate:e?.finishDate
    }

    console.log(body)

    HTTPService.POST(`/Dashboard/inventoryByCounting`, body).then((response) => {
      console.log(response.data)
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        setCountingJobData(response.data)
        console.log(response.data)
      } else {
        toast.error(response.data);
      }
    });
  };



  console.log(barData)
  const dataSource2 = barData.map(item => [
    item.labelName,
    item.totalCount
  ]);
  
  const barChartData = [
    ['Label', 'Percentage'],
    ...dataSource2,
  ];

  const options = {
    title: 'Counting Jobs Overview',
    legend: { position: 'top' },
    hAxis: {
      title: 'Percentage',
      minValue: 0,
      maxValue: 100,
    },
    vAxis: {
      title: 'Label',
    },
  };

  const combinedData = chartData.flatMap(item => [
    { month: item.month, value: item.completeCount, type: 'Tamamlanan Sayım'},
    { month: item.month, value: item.totalCount - item.completeCount, type: 'Kalan Sayım' },
  ]);

  const filteredData = combinedData.filter(item => item.value >= 0); // Negatif değerleri filtrele

  const configs = {
    data: filteredData,
    xField: 'month',
    yField: 'value',
    stack: true,
    colorField: 'type',
    yAxis: {
      min: 0,
    },
    tooltip: {
      title: (item) => `${item.month}`,
      formatter: (datum) => {
        const completedItem = filteredData.find(item => item.month === datum.month && item.type === 'Tamamlanan');
        const remainingItem = filteredData.find(item => item.month === datum.month && item.type === 'Kalan');
        return {
          value: `${datum.countingJobName} : ${completedItem ? completedItem.value : 0}: ${remainingItem ? remainingItem.value : 0}`
        };
      },
    },
    label: {
      content: (data) => `${data.value}`,
      textBaseline: 'bottom',
      position: 'inside',
    },
    interaction: {
      elementHighlightByColor: {
        link: true,
      },
    },
    state: {
      active: { linkFill: 'rgba(0,0,0,0.25)', stroke: 'black', lineWidth: 0.5 },
      inactive: { opacity: 0.5 },
    },
    legend: {
      position: 'top',
      itemHeight: 20, 
      itemMargin: 10, 
      layout: 'horizontal'
    },
  };
  
  
  const config = {
    data: chartData.length > 0 ? chartData : [],
    xField: 'month',
    yField: 'rainfall',
    colorField: 'name',
    group: true,
  };

  const customShapeConfig = {
    data: shapeData,
    xField: 'type',
    yField: 'Kategori',
    scale: {
      count: {
        nice: true, 
        alias: 'category',
        formatter: (value) => Math.floor(value), 
      },
    },
  };
  
  const pieConfig = {
    data: pieData,
    angleField: 'Envanter_Sayısı',
    colorField: 'type',
    radius: 0.8,
    label: {
      text: (d) => `${d.type}`,
      position: 'outside',
    },
    legend: {
      position: 'bottom', 
      layout: 'vertical',
      maxItemsPerRow: 4,
      item: {
        name: {
          style: {
            fontWeight: 'bold',
            fontSize: 8,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
      itemMargin: 5,
      offsetY: 20, 
    },
    // width: 570,
    height: 420,
    padding: 'auto', 
    autoFit:true
  };
  
  const barConfig = {
    data: barData,
    xField: 'labelName',
    yField: 'percentage',
    paddingRight: 80,
    style: { maxWidth: 25 },
    markBackground: {
      label: {
        text: ({ originData }) => `Tamamlanan: ${originData.completeCount} | Toplam: ${originData.value}`,
        position: 'right',
        dx: 50,
        style: { fill: '#aaa', fillOpacity: 1, fontSize: 14 ,marginLeft:100},
      },
      style: { fill: '#eee' },
    },
    scale: {
      y: { domain: [0, 100] }, 
    },
    axis: {
      x: { tick: false, title: false },
      y: { grid: false, tick: false, label: false, title: false },
    },
    interaction: { elementHighlight: false },
  };
  
  const columnsCountingJob:IAltisDataGridColumns[] = [
    {
      title: t('columnsName'),
      key: 'countingJobName',
      placeHolder: t('columnsName'),
      inputType: 'operations',
      align:'center',
      width:10,
    },
    {
      title: t('responsible'),
      key: 'responsibleName',
      placeHolder: t('responsible'),
      inputType: '',
      width:10,
      align:'left'
    },
    {
      title: t('remainingDay'),
      key: 'remainingTime',
      placeHolder: t('remainingDay'),
      inputType: 'text',
      width:50,
      render: (value) => {
        return <div>{t(value)}</div>
      }
    },
    {
      title: t('date'),
      key: 'date',
      placeHolder: t('date'),
      inputType: 'operations',
      align:'center',
      width:100,
      render: (text, record) => {
        const startDate = new Date(record.startDate).toLocaleDateString('tr-TR');
        const finishDate = new Date(record.finishDate).toLocaleDateString('tr-TR');
        return `${startDate}-${finishDate}`;
      },
    },
  ];
  

  const columns1:IAltisDataGridColumns[] = [
    {
      title: t('inventoryName'),
      key: 'inventoryName',
      placeHolder: t('inventoryName'),
      inputType: 'input',
    },
    {
      title: t('InventoryStatus'),
      key: 'inventoryStatusName',
      placeHolder: t('InventoryStatus'),
      inputType: 'input'
    },
    {
      title: t('event'),
      key: 'inventoryTracking',
      placeHolder: t('event'),
      inputType: 'operations',
      render: (value) => {
        return <div>{t(value)}</div>
      }
    },
    {
      title: t('debit'),
      key: 'responsibleName',
      placeHolder: t('debit'),
      inputType: 'input'
    },
    {
      title: t('date'),
      key: 'updatedDate',
      placeHolder: t('date'),
      inputType: 'operations',
      render: (text, record) => {
        const updatedDate = new Date(record.updatedDate).toLocaleDateString('tr-TR');
        return `${updatedDate}`;
      },
    }
  ];

  return (
    <>
      <Alert
        className='mb-4'
        message={t('Welcome to The Tag And Trace Assets System')}
        type='success'
        showIcon
      />
  
      <Row gutter={4}>
        <Col span={12}>
          <Card title={t("inventory_status_name")} style={{ marginTop: '5px', width: '100%', height: '93%'}}>
            {pieData && pieData.length>0?(
                   <div style={{ width: '80%', margin: '0 auto', padding: '20px' }}>
                   <Pie {...pieConfig} />
                 </div>
            ):(
              <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card title={t("category")} style={{ marginTop: '5px', width: '100%',height:'93%' }}>
            {shapeData && shapeData.length>0?(
                
                <div style={{ width: '70%', margin: '0 auto' }}>
                  <Column {...customShapeConfig} />
                </div>
          ) :(
            <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
          )}
          </Card>
        </Col>
        <Col span={12}>
          <Card title={t("countingJobs")} style={{ marginTop: '5px', width: '100%', height:'93%' }}>
            {filteredData && filteredData.length > 0 ? (
              <div style={{ width: '70%', margin: '0 auto' }}>
                <Column {...configs} />
              </div>
            ) : (
              <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
            )}
          </Card>
        </Col>

        <Col span={12}>

        <Card title={t("countingJobsNow")} style={{ marginTop: '5px', width: '100%', height: '93%' }}>
          {countingJobData && countingJobData.count>0?(
                 <div style={{ padding: '50px' }}>
                 <AltisDataGrid
                   data={countingJobData?.data}
                   columns={columnsCountingJob}
                   total={countingJobData?.count}
                   onChange={CountingFilter}
                 />
                 </div>
          ):(
            <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
          )}
        </Card>
      </Col>
      </Row>
      <Row gutter={4}>
       <Col span={24}>
          <Card title={t("movements")} style={{ marginTop: '5px', width: '100%', height: 'auto' }}>
            {historyData && historyData.count>0?(
               <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
               <AltisDataGrid
                 data={historyData?.data}
                 columns={columns1}
                 total={historyData?.count}
                 onChange={TrackingFilter}
               />
             </div>
            ):(
              <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
  
};

export default Dashboard;
