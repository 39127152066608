import { DeleteOutlined, EditOutlined, ForwardOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Popconfirm, Space, Tooltip } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import AltisDataGrid, { IDropdownItem } from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import "../CompanyManagement/CustomerManagement.css";
import { IApplicationData } from "../../../interfaces/IApplicationData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next'; // Ekleyin
import { ITypeData } from "../../../interfaces/ITypeData";
import { ICounting } from "../../../interfaces/ICountingFilter";
import { t } from "i18next";
import i18n from "../../../i18n";


const CountingManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const loader = new StateLoader();
  const [coutingData, setCoutingData] = useState<ICounting>({});
  const userInfo: IAuthentication = loader.loadAuthState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [newIsCompleted, setNewIsCompleted] = useState(false);

  const dataGridRef = useRef<any>(null);
  // useEffect(() => {
  //     CountingFilter();
  //     const intervalId = setInterval(() => {
  //         if (dataGridRef.current) {
  //             dataGridRef.current.clearData();
  //         }
  //     }, 1000);
  //     return () => clearInterval(intervalId);
  // }, [])


  const pageData: IPageData = {
    title: t('Counting Management'),
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    CountingFilter();
  }, [i18n.language]);

  const dropdownCountStatus: IDropdownItem[] = [
    { id: 0, name: t('Expired') },
    { id: 1, name: t('In_Progress') },
    { id: 2, name: t('Not_Started') },
    { id: 3, name: t('Completed') },
  ];

  const handleCheckboxChange = (countingJobId, isCompleted) => {
    // State'i güncelle
    const updatedData = coutingData.data.map(item => 
      item.countingJobId === countingJobId ? { ...item, isCompleted: isCompleted } : item
    );
  
    // API çağrısını yap
    const body = {
      countingJobId: countingJobId,
      isCompleted: isCompleted
    };

    console.log(body);
  
     HTTPService.PUT(`/CountingJob/countingIsCompleted`, body)
       .then(response => {
         if (response.status === 200) {
           toast.success(t('counting_update'));
         } else {
           toast.error(t('update_failed'));
         }
       })
       .catch(error => {
         toast.error(t('Error occurred'));
      });

      setCoutingData({ ...coutingData, data: updatedData });

  };
  
  
  // const updateCounting = (id, isCompleted) => {
  //   const body = { countingJobId: id, isCompleted };
  
  //   HTTPService.PUT(`/CountingJob/countingIsCompleted`, body)
  //     .then(response => {
  //       if (response.status === 200) {
  //         toast.success(t('Update successful'));
  //       } else {
  //         toast.error(t('Update failed'));
  //       }
  //     })
  //     .catch(error => {
  //       toast.error(t('Error occurred'));
  //     });
  // };
  
  
  

  const columns: IAltisDataGridColumns[] = [
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      placeHolder: t('Company Name'),
      inputType: 'input'
    }] : []),
    {
      title: t('Counting Job Name'),
      key: 'countingJobName',
      placeHolder: t('Counting Job Name'),
      inputType: "input"
    },
    {
      title: t('campus'),
      key: 'campusName',
      placeHolder: t('campus'),
      inputType: "input"
    },
    {
      title: t('Responsible Name'),
      key: 'firstName',
      placeHolder: t('Responsible Name'),
      inputType: "input"
    },
    {
      title: t('Counting Status'),
      key: 'countingStatus',
      placeHolder: t('Counting Status'),
      inputType: "operations",
      dropdownItem: dropdownCountStatus,
      render: (value) => <div>{t(value)}</div>
    },
    {
      title: t('start_date'),
      key: 'startDate',
      placeHolder: t('start_date'),
      inputType: "date",
      width: 1000
    },
    {
      title: t('finish_date'),
      key: 'finishDate',
      placeHolder: t('finish_date'),
      inputType: "date",
      width: 1000
    },
  ];

  const showModal = (countingJobId, currentStatus) => {
    setSelectedJobId(countingJobId);
    setNewIsCompleted(!currentStatus); // Checkbox'ın tersini ayarlayın
    setIsModalVisible(true); // Modalı aç
  };

  const handleOk = () => {
    handleCheckboxChange(selectedJobId, newIsCompleted); // Durumu güncelle
    setIsModalVisible(false); // Modalı kapat
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Modalı kapat
  };
  
  // isCompleted kolonunu yalnızca Admin ve AltisAdmin için ekle
 
  if (userInfo.role === UserRole.Admin || userInfo.role === UserRole.AltisAdmin) {
    columns.push({
      title: t('isCompleted'),
      key: 'isCompleted',
      placeHolder: t('isCompleted'),
      inputType: 'operations',
      render: (text, record) => (
        <Tooltip title={record.isCompleted ? t('isCompletedMessageComp') : t('isCompletedMessage')}>
          <Button
            onClick={() => showModal(record.countingJobId, record.isCompleted)} 
            disabled={record.isCompleted} 
            size="small"
          >
            {record.isCompleted ? t('Completed') : t('jobsCompleted')}
          </Button>
        </Tooltip>
      ),
    });
  }
  
  
  
  columns.push({
    title: t('Operations'),
    key: 'operations',
    placeHolder: t('Operations'),
    inputType: "operations",
    render: (order, record) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Space size={10}>
          <Popconfirm
            title={<div>{t('are_you_sure')} <br /></div>}
            onConfirm={() => DeleteCounting(record.countingJobId)}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <Tooltip title={t('delete')}>
              <Button
                size='small'
                icon={<DeleteOutlined />}
                onClick={(e) => { e.stopPropagation(); }}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px' }}
              >
              </Button>
            </Tooltip>
            
          </Popconfirm>

          <Tooltip title={t('update')}>
            <Button
              size='small'
              icon={<EditOutlined />}
              onClick={() => window.location.replace('/admin/update-counting/' + record.countingJobId)}
              style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px'  }}
              disabled={record.isCompleted} // isCompleted true ise update butonu disable 
            >
            </Button>
          </Tooltip>
       
          <Tooltip title={t('details')}>
            <Button
              size='small'
              style={{width: '60px',borderRadius:'70px'}}
              icon={<StarOutlined />}
              onClick={() => window.location.replace('/admin/details-counting/' + record.countingJobId)}
            >
            </Button>
          </Tooltip>
        
        </Space>
      </div>
    ),
  });
  

  const CountingFilter = (e?) => {
    const body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      countingJobName: e?.countingJobName,
      companyName: e?.companyName,
      campusName: e?.campusName,
      locationName: e?.locationName,
      countingStatus:e?.countingStatus,
      isCompleted: e?.isCompleted,
      responsibleUserName:e?.firstName,
      startDate_Start:
        e?.startDate_Start === null || e?.startDate_Start === ''
          ? null
          : new Date(Date.parse(e?.startDate_Start)),
      startDate_Finish:
        e?.startDate_Finish === null || e?.startDate_Finish === ''
          ? null
          : new Date(Date.parse(e?.startDate_Finish)),

      finishDate_Start:
        e?.finishDate_Start === null || e?.finishDate_Start === ''
          ? null
          : new Date(Date.parse(e?.finishDate_Start)),
      finishDate_Finish:
        e?.finishDate_Finish === null || e?.finishDate_Finish === ''
          ? null
          : new Date(Date.parse(e?.finishDate_Finish)),
    };
    console.log(body);
    HTTPService.POST(`/CountingJob/filters`, body).then((response) => {
      if (!response.data) return;
      if (response.status === 200) {
        console.log(response.data);
        setCoutingData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const CountingStart = (Id) => {
    console.log(Id)
    HTTPService.GET(`/CountingJob/CountingStart?countingJobId=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(response.data);
          window.location.reload();
        } else {
          toast.error(response.data);
        }
      }
    );
  };

  const DeleteCounting = (Id) => {
    HTTPService.DELETE(`/CountingJob/deleteCounting?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        } else {
          toast.error(t(response.data));
        }
      }
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '25px 30px 30px 25px', marginBottom: 20, display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-counting')}
          >
            {t('Add New Counting')}
          </Button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='table-container'>
            <AltisDataGrid  ref={dataGridRef} data={coutingData.data} total={coutingData.count} columns={columns} onChange={CountingFilter} />
          </div>
        </div>
      </div>
      <Modal
        title={<span style={{ fontWeight: 'bold' }}>{t('isCompleted')}</span>} 
        visible={isModalVisible}
        onOk={handleOk} 
        onCancel={handleCancel}
      >
        <p>{t('isCompletedModalMessage')}</p>
    </Modal>
    </>
  );
};

export default CountingManagement;
