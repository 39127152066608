import React, { useEffect, useState } from 'react';
import { IPageData, IPageProps } from "../../interfaces/page-data";
import { Button, Input, Popconfirm, Space, Tooltip } from "antd";
import { toast } from "react-hot-toast";
import HTTPService from "../../utils/makerequest";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { StateLoader } from '../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../interfaces/authentication';
import AltisDataGrid, { IDropdownItem } from "../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../AltisComponent/AltisDataGrid";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { PersonelRol } from '../../interfaces/group';

interface IUserData {
  firstName: string,
  password?: string,
  personelStatus: PersonelRol
}

interface IUsers {
  count?: number,
  data?: IUserData[];
}

const PagePalletData: React.FunctionComponent<IPageProps> = props => {
  const { onSetPage } = props;
  const [userData, setUserData] = useState<IUsers>({});
  const { t } = useTranslation();

  const pageData: IPageData = {
    title: t('user_settings'),
    loaded: true
  };

  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();

  useEffect(() => {
    onSetPage(pageData);
    UserFilter();
  }, [i18n.language]); // onSetPage, pageData

  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: t('Sayman') },
    { id: 1, name: t('Personel') },
    { id: 2, name: t('Operator') },
    { id: 3, name: t('Admin') },
    { id: 4, name: t('AltisAdmin') },
  ];
  const dropdownPersonelType: IDropdownItem[] = [
    { id: 0, name: ('Active_Personnel') },
    { id: 1, name: t('Allowed_Personnel') },
    { id: 2, name: t('Resigner_Personnel') },
    { id: 3, name: t('Dismissed_Personnel') },
    { id: 4, name: t('Retired_Personnel') },
  ];

  const UserFilter = (e?) => {
    var body = {
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      companyName: e?.companyName,
      firstName: e?.firstName,
      lastName: e?.lastName,
      userEmail: e?.userEmail,
      role: e?.role,
      companyId: e?.companyId,
      personelStatus:e?.personelStatus
    };


    HTTPService.POST(`/User/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        console.log(response.data)
        setUserData(response.data);
      } else {
        toast.success(response.data);
      }
    });
    console.log(body)

  };

  const DeleteUser = (id, email) => {
    HTTPService.DELETE(`/account/delete?Id=${id}`).then(
      (response) => {
        if (!response.data) {
          return;
        }
        toast.success(t('delete_success'));
        window.location.reload();
        if (userInfo.email === email) {
          loader.removeAuthLoader();
        }
      }
    );
  };

  const columns: IAltisDataGridColumns[] = [
    ...(userInfo.role !== UserRole.Admin ? [
      {
        title: t('company_name'),
        key: 'companyName',
        placeHolder: t('company_name'),
        inputType: "input",
      }
    ] : []),
    {
      title: t('email'),
      key: 'userEmail',
      placeHolder: t('email'),
      inputType: "input",
    },
    {
      title: t('firstName'),
      key: 'firstName',
      placeHolder: t('firstName'),
      inputType: "input",
    },
    {
      title: t('lastName'),
      key: 'lastName',
      placeHolder: t('lastName'),
      inputType: "input",
    },
    {
      title: t('role'),
      key: 'role',
      placeHolder: t('role'),
      inputType: 'operations',
      dropdownItem: dropdownItemType,
      render: (value) => {
        return <div>{t(value)}</div>
      }
    },
    {
      title: t('Personel Status'),
      key: 'personelStatus',
      placeHolder: t('Personel Status'),
      inputType: 'operations',
      dropdownItem: dropdownPersonelType,
      render: (value) => {
        return <div>{t(value)}</div>
      }
    },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: "operations",
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Space size={50}>
            {/* {userInfo.email !== record.email && (
              <Popconfirm
                title={t('delete_confirmation')}
                onConfirm={() => {
                  DeleteUser(record.userId, record.email);
                }}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  onClick={(e) => { e.stopPropagation(); }}
                >
                  {t('delete')}
                </Button>
              </Popconfirm>
            )} */}
                    <Tooltip title={t('update')}>
                      <Button
                          size='small'
                          icon={<EditOutlined />}
                          style={{width: '60px',borderRadius:'70px'}}
                          onClick={() => {
                            window.location.replace('/admin/update-user/' + record.userId);
                          }}
                        >
                        </Button>
                    </Tooltip>
       
          </Space>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '25px 30px 30px 25px', marginBottom: 20, display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-user')}
          >
            {t('add_new_user')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <AltisDataGrid data={userData.data} total={userData.count} columns={columns} onChange={UserFilter} />
        </div>
      </div>
    </>
  );
}

export default PagePalletData;
